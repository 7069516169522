import React from "react";
import {classNames} from "../utils";

type Props = {
  className?: string
}

const PostContainer: React.FC<Props> = ({className = "", children}) => {
  return (
    <div className={classNames("flex flex-wrap justify-between pt-12 -mx-6", className)}>
      {children}
    </div>
  );
};

export default PostContainer
