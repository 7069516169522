import * as React from "react"
import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import LeadPost from "../components/LeadPost";
import PostContainer from "../components/PostContainer";
import PostCard from "../components/PostCard";
import {createNavItems} from "../utils/nav-items-util";
import usePosts from "../hooks/use-posts";

const BlogPage = () => {
  const posts = usePosts();
  const lead = posts[0];
  const sizes = ["sm", "sm", "sm", "md", "md", "lg", "sm",]
  const subs = posts.slice(1).map((post, index) => {
    const size = sizes[index % sizes.length] as any;
    return (<PostCard post={post} size={size}/>);
  });

  return (
    <Layout navItems={createNavItems({current: "blog"})}>
      <PageWrapper className={"mt-16 mb-16"}>
        {lead && <LeadPost post={lead}/>}
        {subs && (<PostContainer>{subs}</PostContainer>)}
      </PageWrapper>
    </Layout>
  )
};

export default BlogPage
