import React from "react";
import {PostEntity} from "../entity/post-entity";
import {truncate} from "../utils";
import {MAX_SUMMARY_CHARACTERS} from "../config";

type Props = {
  post: PostEntity
}

const LeadPost: React.FC<Props> = ({post}) => {
  const {title, summary, href, coverImageUrl, createdAt, readTime, tags} = post
  const description = truncate(summary, MAX_SUMMARY_CHARACTERS)
  const tag = (tags.length > 0 ? tags[0] : "").toUpperCase()
  return (
    <div className="bg-gray-200 w-full text-xl md:text-2xl text-gray-800 leading-normal rounded-t">
      <div className="flex h-full bg-white rounded overflow-hidden shadow-md hover:shadow-xl">
        <a href={href} className="flex flex-wrap no-underline hover:no-underline">
          <div className="w-full md:w-2/3 rounded-t">
            <img src={coverImageUrl} alt={title} className="object-cover h-full w-full shadow"/>
          </div>

          <div className="w-full md:w-1/3 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden shadow-lg">
              <p className="w-full text-gray-600 text-xs md:text-sm pt-6 px-6">{tag}</p>
              <div className="w-full font-bold text-xl text-gray-900 px-6">{title}</div>
              <p className="text-gray-800 font-serif text-base px-6 mb-5">{description}</p>
            </div>

            <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden shadow-lg p-6">
              <div className="flex items-center justify-between">
                <p className="post-text-footer">{"📅 " + createdAt}</p>
                <p className="post-text-footer"> {"🕔 " + readTime}</p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
}

export default LeadPost;
