import React from "react";
import {classNames, truncate} from "../utils";
import {PostEntity} from "../entity/post-entity";
import {MAX_SUMMARY_CHARACTERS} from "../config";

type Size = 'sm' | 'md' | 'lg'
type Props = {
  post: PostEntity
  size?: Size
}

function widthClass(size: Size) {
  switch (size) {
    case "sm":
      return "md:w-1/3";
    case "md":
      return "md:w-1/2";
    case "lg":
      return "md:w-2/3";
  }
  return "";
}

const PostCard: React.FC<Props> = ({post, size = 'sm'}) => {
  const {title, summary, href, coverImageUrl, createdAt, readTime, tags} = post
  const description = truncate(summary, MAX_SUMMARY_CHARACTERS)
  const tag = (tags.length > 0 ? tags[0] : "").toUpperCase();
  return (
    <React.Fragment>
      <div className={classNames("w-full p-6 flex flex-col flex-grow flex-shrink", widthClass(size))}>
        <div className="flex flex-col flex-grow flex-shrink shadow-md hover:shadow-xl">
        <div className="flex-1 bg-white rounded-t rounded-b-none overflow-hidden">
          <a href={href} className="flex flex-wrap no-underline hover:no-underline">
            <img src={coverImageUrl} className="object-cover h-64 w-full rounded-t pb-6" alt={title}/>
            <p className="w-full text-gray-600 text-xs md:text-sm px-6">{tag}</p>
            <div className="w-full font-bold text-xl text-gray-900 px-6">{title}</div>
            <p className="text-gray-800 font-serif text-base px-6 mb-5">{description}</p>
          </a>
        </div>
        <div className="flex-none mt-auto bg-white rounded-b rounded-t-none overflow-hidden p-6">
          <div className="flex items-center justify-between">
            <p className="post-text-footer">{"📅 " + createdAt}</p>
            <p className="post-text-footer">{"🕔 " + readTime}</p>
          </div>
        </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PostCard;
