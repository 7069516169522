import {graphql, useStaticQuery} from "gatsby"
import {PostEntity} from "../entity/post-entity";
import dayjs from "dayjs";
import {DEFAULT_DATE_FORMAT} from "../config";

type Props = {
  allPrismicPosts: {
    nodes: {
      uid: string
      url: string
      tags: string[]
      first_publication_date: string
      last_publication_date: string
      data: {
        title: {
          text: string
        }
        cover: {
          url: string
        }
        summary: {
          text: string
        }
        read_time: number
      }
    }[]
  }
}

const usePosts: () => PostEntity[] = () => {
  const data = useStaticQuery<Props>(graphql`
    query AllPosts {
      allPrismicPosts(sort: {fields: first_publication_date, order: DESC}) {
        nodes {
          uid
          url
          tags
          first_publication_date
          last_publication_date
          data {
            title {
              text
            }        
            cover {
              url
            }
            summary {
              text
            }
            read_time
          }
        }
      }
    }
  `)
  return data.allPrismicPosts.nodes.map(value => {
    const {uid, first_publication_date, data} = value;
    const createdAt = dayjs(first_publication_date).format(DEFAULT_DATE_FORMAT)
    return {
      id: uid,
      tags: [],
      createdAt: createdAt,
      title: data.title.text,
      summary: data.summary.text,
      readTime: `${Math.max(1, data.read_time)} min read`,
      href: `/blog/${uid}`,
      coverImageUrl: data.cover.url,
    } as PostEntity
  });
}

export default usePosts
